import * as React from 'react';
import * as ReactDOM from 'react-dom';

import './index.css';
import 'antd/dist/antd.css';
import Routes from './Routes';

// import 'ant-design-pro/dist/ant-design-pro.css';


ReactDOM.render(<Routes />, document.getElementById("root") as
HTMLElement);
