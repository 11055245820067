import * as React from "react"
import { instance } from "../axios-instance"
import { Link } from "react-router-dom";

import Menu from 'antd/lib/menu'
import Modal from 'antd/lib/modal'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Button from 'antd/lib/button'
import message from 'antd/lib/message'
import Dropdown from 'antd/lib/dropdown'
import UserOutlined from "@ant-design/icons/UserOutlined"

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};
interface IState {
    user: any;
    visible: boolean;
    loggedIn: boolean;
    changePassword: boolean;
}
class Login extends React.Component<{}, IState> {
    constructor(props: any) {
        super(props);
        this.state = {
            visible: false,
            loggedIn: false,
            changePassword: false,
            user: {},
        }
    }
    onSubmit = (val: any) => (instance.post('login', { email: val.email, password: val.psw })
        .then((res: any) =>
            res.data !== 'Invalid Login' ?
                this.setState({ user: res.data, visible: false, loggedIn: true })
                : (message.error(res.data as string), this.setState({ loggedIn: false, visible: false }))
        ).catch((err: any) => { message.error(err as string); this.setState({ loggedIn: false, visible: false }) }
        ))

    onSubmitChange = (val: any) => instance.post('change_password', { email: this.state.user.email, new_password: val.password })
        .then((res: any) =>
            res.data === 'Password changed successfully' ?
                (message.success(res.data as string), this.setState({ visible: false, changePassword: false }))
                : (message.error(res.data as string), this.setState({ visible: false, changePassword: false }))
        ).catch((err: any) => { message.error(err as string); this.setState({ visible: false, changePassword: false }) }
        )
    render() {
        return (<>
            <Modal
                title="Login"
                visible={this.state.visible}
                footer=''
                onCancel={() => this.setState({ visible: false, changePassword: false })}
            > {!this.state.changePassword ? <Form
                {...layout}
                name="basic"
                onFinish={this.onSubmit}
            //onFinishFailed={onFinishFailed}
            >
                <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: 'Please input your email!' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Password"
                    name="psw"
                    rules={[{ required: true, message: 'Please input your password!' }]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit">
                        Submit</Button>
                </Form.Item>
            </Form> :
                <Form
                    {...layout}
                    name="reset"
                    onFinish={this.onSubmitChange}
                //onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        label="password"
                        name="password"
                        rules={[{ required: true, message: 'Please input your email!' }]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        label="repeat password"
                        name="repeat password"
                        rules={[
                            {
                                required: true,
                                message: 'Please confirm your password!',
                            },
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject('The two passwords that you entered do not match!');
                                },
                            }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit">
                            Submit</Button>
                    </Form.Item>
                </Form>
                }
            </Modal>
            {!this.state.loggedIn ?
                <Button onClick={() => this.setState({ visible: true })}>Login</Button>
                : <Dropdown overlay={
                    <Menu>
                        <Menu.Item key="6"><Link to="/edit">Insert Papyri</Link></Menu.Item>
                        <Menu.Item onClick={() => this.setState({ changePassword: true, visible: true })}>Change Password</Menu.Item>
                        <Menu.Item onClick={() => this.setState({ loggedIn: false })}><Link to="/">Logout</Link></Menu.Item>
                    </Menu>
                }><Button icon={<UserOutlined />}>{this.state.user.name} {this.state.user.surname}</Button>
                </Dropdown>}
        </>)
    }
}

export default Login