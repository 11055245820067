import * as React from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import Layout from 'antd/lib/layout';
import Menu from 'antd/lib/menu'

import Home from './components/Home'
import Sample from './components/Sample'
import logo from './assets/PBas_logo.jpg'
import logo1 from './assets/SNF_Logo1.png'
// import Software from "./components/Software"
import Login from "./components/Login"
import Edit from "./components/Edit"
import Papyri from './components/Papyri'
import useWindowSize from "./useWindowSize";



const { Header, Content, Footer } = Layout;

/** 
 * BrowserRouter is the top-level component and this looks for Route
 * components beneath it to determine all the different page paths.
 * During rendering, if the path in a Route component matches the current
 * path, the component will be rendered, and if not, null will be rendered.
 */

const Routes: React.SFC = () => {
    const onClickMenu = (({ item, key }: any) => setKey(key))
    const [key, setKey] = React.useState("1")

    const size = useWindowSize()
    return (
        <Router>
            <div>
                <Layout style={{ height: window.outerHeight }}>
                    <Header style={{ backgroundColor: 'white', height:78 }}>
                        <Link to='/'>
                        <img alt='' src={logo} style={{ position: 'absolute', top: '0.2%', left: '2%', width: 220, height:75   }} />
                        <img alt='' src={logo1} style={{ position: 'absolute', top: '0.2%', right: '2%', width: 220, height:70   }} />
                        </Link>
                        <Menu onClick={onClickMenu} mode="horizontal" style={{ position: 'absolute', right: '43%' , height:65}}>
                            <Menu.Item key="1" defaultChecked={true}><Link to="/">Home</Link></Menu.Item>

                            <Menu.Item key="2"><Link to="/Papyri">Papyri</Link></Menu.Item>
                            <Menu.Item key="3" ><Login /></Menu.Item>
                        </Menu>
                    </Header>
                    <Content style={{ fontFamily:'Verdana', padding: '0 50px 0 50px', marginTop: '2.5%' , marginBottom: '2,5%'}}>
                        <div style={{ height: '100%', overflowY: key === '2' || key === '3' || size.height <= 800 ? 'scroll' : 'hidden' }}>
                            <Route component={Home} exact={true} path='/' />
                            <Route component={Sample} path='/Sample' />
                            <Route component={Papyri} path='/Papyri' />
                            <Route component={Papyri} path='/Papyri/:id' />
                            <Route component={Edit} exact={true} path='/edit' />
                        </div>
                    </Content>
                    <Footer style={{ fontFamily:'Verdana', textAlign: 'center', background: 'white'}}>Basel Papyri ©{new Date().getFullYear()} All rights reserved</Footer>
                </Layout>
            </div>
        </Router >
    );
};
export default Routes;
