
/* eslint-disable */
import * as React from "react"
import { instance } from "../../axios-instance"

import Select from 'antd/lib/select'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Button from "antd/lib/button"
import Divider from "antd/lib/divider"
import message from "antd/lib/message"
import Space from 'antd/lib/space'
import Popover from 'antd/lib/popover'
import Upload from 'antd/lib/upload'
import TextArea from 'antd/lib/input/TextArea'

import UploadOutlined from '@ant-design/icons/UploadOutlined'
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined'
import PlusOutlined from '@ant-design/icons/PlusOutlined'


const { Option } = Select
interface IState {
    papyris: any
    height: any;
    selectedFieldsPapyri: FieldData[];
    selectedPapyri: any
    selectedOption: string;
    PPortal: string
    TM: string
    PapyriInfo: string
    Title: string
    Publications: string
    ['Inv. Number']: string
    ['Inv. Number before 2015']: string
    Material: string
    Size: string;
    ['Ink Analysis']: string;
    ['Other side']: string;
    ['Language']: string,
    ['Other side papyrus']: string;
    Date: string;
    Provenance: string;
    Content: string;
    Translation: string;
    Bibliography: string
    Comments: string;
    //['Papyri Images']: any;
    //PapyriImagesBackend: any;
    //inkName: string
}

interface IProps {
    getPapyris: any,
    papyris: []
}

interface FieldData {
    name: string[];
    value: any;
    touched?: boolean;
    validating?: boolean;
    errors?: string[];
}

const base64Image = (info: any) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(info.file.originFileObj);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    })
}

// PPortal: https://papyri.uni-leipzig.de/receive/BslPapyri_text_00000480
// TM: www.trismegistos.org/text/30799
// papyri.info http://papyri.info/ddbdp/p.bas;2;43
// Title (as in the P.Bas. II edition, PPortal, papyri): 	Christian letter 
// Publications: (as in papyri) 	P.Bas. 2 43, Naldini, Cristianesimo 4, P.Bas. I 16 
// (rather than PPortal: publiziert: P.Bas.II 43 [P.Bas.I 16 r])

// Inv. Number (as in Inv. Id in papyri)  	P.Bas. inv. 16 recto
// Inv.number before 2015: (PPortal other ordering systems)	Inv. alt: 12

// Material (as in papyri) 	Papyrus	/parchment/paper 
// Size: (as in PPortal)	Height : 17.0 cm / Width : 7.5 cm 
// Ink Analysis: (from the edition)	carbon
// Click here to see the samples
// Other side: (TM Recto/verso +Reuse type; papyri Commentary)		
// blank/ continuation of the same text or endorsement/ other text (link)		P.Bas. II 44

// Date (from the edition, papyri and PPortal have it in German) 	Before 239 CE
// Provenance (TM geo ID, specify found/written)		Theadelphia
// Content (to be done)
// Translation (from the edition, to add in papyri)
// Bibliography (post edition, to be kept updated…)
// Comments (post-edition information on the text, with possibility to clearly identify the source, ex Dr. Eirini Afentoulidou, email communication 08.10.2020: “This text can be compared with….”)

// IIIF Viewer: original with scale, infra-red, enhanced (6-8 images); verso if not another text 
// Possibility to chose what image to display

class Papyri extends React.Component<IProps, IState> {

    constructor(props: any) {
        super(props)
        this.state = {
            //['Papyri Images']: [],
            papyris: [],
            height: [],
            selectedPapyri: {},
            selectedOption: '',
            selectedFieldsPapyri: [],
            PPortal: '',
            TM: '',
            PapyriInfo: '',
            Title: '',
            Publications: '',
            ['Inv. Number']: '',
            ['Inv. Number before 2015']: '',
            Material: '',
            Size: '',
            ['Ink Analysis']: '',
            ['Other side']: '',
            ['Language']: '',
            ['Other side papyrus']: '',
            Date: '',
            Provenance: '',
            Content: '',
            Translation: '',
            Bibliography: '',
            Comments: '',
            //inkName: '',
            //PapyriImagesBackend: {}
        }
    }

    componentDidMount() {
        this.setState({ height: window.innerHeight })
        this.setState({ papyris: this.props.papyris })
    }
    componentDidUpdate(prevProps: any) {

        prevProps.papyris != this.props.papyris ? this.setState({ papyris: this.props.papyris }) : ''
    }
    // handleChangeImage = (info: any, type: string) => {
    //     var image: any
    //     info.file.status != 'removed' ?
    //         base64Image(info)
    //             .then(res => image = res)
    //             .finally(() => {
    //                 console.log(image)
    //                 const images = this.state['Papyri Images'].findIndex((v: any) => v.uid == info.file.uid) == -1 ?
    //                     Object.assign([], this.state['Papyri Images']).concat({
    //                         name: info.file.name,
    //                         url: image,
    //                         uid: info.file.uid
    //                     }) : this.state['Papyri Images']
    //                 this.setState({
    //                     ["Papyri Images"]: images
    //                 })
    //             }) : (
    //             this.setState({
    //                 ["Papyri Images"]: this.state["Papyri Images"].filter((papyri: any) => papyri.uid != info.file.uid),
    //                 // PapyriImagesBackend: this.state.PapyriImagesBackend.filter((papyri: any) => papyri.uid != info.file.uid)
    //             })
    //         )
    // }

    //type === 'papyri' ? this.setState({ loadingPapyri: true }) : this.setState({ loadingInk: true })

    // this.setState({ ['Papyri Images']: uploadedLogo.result })
    // this.setState({ loadingInk: false, ["Ink Analysis"]: uploadedLogo.result, inkName: info.file.originFileObj.name })
    // uploadedLogo.onerror = err => type === 'papyri' ?
    //     (this.setState({ loadingPapyri: false }), response = err as unknown as string, message.error(err as unknown as string)) :
    //     (this.setState({ loadingInk: false }), response = err as unknown as string, message.error(err as unknown as string))
    // uploadedLogo.onloadend = end => response = end as unknown as string
    // return response
    selectPapyri = (type: any) => (
        // instance.post('get_papyri', this.state.selectedPapyri.uuid).then(res => images = res.data).finally(() => 
        type == 'create' && this.setState({ selectedPapyri: { Title: '', uuid: '' } }),
        this.setState({
            selectedOption: type,
            selectedFieldsPapyri:
                (Object.keys(this.state)
                    .map(val => {
                        return {
                            name: [val],
                            value: type == 'edit' ? this.state.selectedPapyri[val] : ''
                        }
                        // : {
                        //         name: [val],
                        //         value: type == 'edit' ? {
                        //             uuid: this.state.selectedPapyri[val],
                        //             Title: this.state.papyris.find((payri: any) => payri.uuid == this.state.selectedPapyri[val])?.Title
                        //         } : ''
                        //     }
                        //  : {
                        //         name: [val],
                        //         value: images ? images.map((val: any) => {
                        //             return {
                        //                 name: val.name,
                        //                 uid: val.uuid,
                        //                 url: val.image,
                        //                 uuid: val.uuid
                        //             }
                        //         }) : []
                        //     }
                    }))
        })
    )

    handleSubmit = (val: any) => (
        // console.log(val)
        this.state.selectedOption == 'create' ?
            instance
                .post('create_papyri', { papyri_info: val /*, images_info: this.state['Papyri Images']*/ })
                .then(res => res.data === 'Papyrus was created' ?
                    (message.success(res.data), this.props.getPapyris(), this.setState({ selectedOption: '' })) :
                    message.error(res.data)
                ).catch(err => message.error(err as string)) :
            instance
                .post('update_papyri', { papyri_info: val, papyri_uuid: this.state.selectedPapyri.uuid/*, images_info: this.state['Papyri Images']*/ })
                .then(res => res.data === 'Papyrus information was updated!' ?
                    (message.success(res.data), this.props.getPapyris(), this.setState({ selectedOption: '' })) :
                    message.error(res.data)
                ).catch(err => message.error(err as string))
    )
    render() {
        const stateList = Object.keys(this.state)
        const selecteOption = this.state.selectedOption
        const otherSideSelected = this.state.selectedFieldsPapyri.find((v: any) => v.name[0] === 'Other side')?.value

        const { selectedPapyri, papyris } = this.state
        //const inkAnalysisSelected = this.state.selectedFieldsPapyri.find((v: any) => v.name[0] === 'Ink Analysis')?.value
        return (
            <div style={{ position: 'relative' }} >
                <Popover content={
                    <div>
                        <p>--&gt;Select a papyrus or click on "Create a new Papyrus" to insert a new one</p>
                        <p>--&gt;Click on "Select" to edit the papyrus or "Delete" to eliminate the papyrus</p>
                        <p>--&gt;Click on submit at the end to apply your changes</p>
                    </div>}>
                    <InfoCircleOutlined style={{ marginRight: '2%' }} />
                </Popover>
                        Select a Papyrus:
                <Select
                    filterOption={(input, option) =>
                        option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    value={selectedPapyri.Title}
                    showSearch
                    optionFilterProp="children"
                    onSelect={uuid => this.setState({
                        selectedPapyri: papyris.find((val: any) => val.uuid === uuid),
                        selectedOption: ''
                    })}
                    style={{ width: '52.5%', marginRight: '2%' }}>
                    {papyris.map((val: any, i: any) =>
                        <Select.Option key={i} value={val.uuid}>{val.Title}</Select.Option>
                    )}
                    {selectedPapyri.Title == '' && selectedPapyri.uuid == '' &&
                        <Select.Option key={'0'} value={''}>{'You are entering a new papyrus'}</Select.Option>}
                </Select>
                <Space>
                    <Button
                        onClick={() => selectedPapyri != {} ?
                            this.selectPapyri('edit') :
                            message.warning('Select a Papyrus before')
                        }>Select</Button>
                    <Button type="primary" danger
                        onClick={() => instance
                            .post('delete_papyri', { papyri_uuid: selectedPapyri.uuid })
                            .then(res => res.data === 'Papyrus was deleted' ?
                                (message.success(res.data), this.props.getPapyris(), this.setState({ selectedOption: '' })) :
                                message.error(res.data)
                            ).catch(err => message.error(err))}
                    >Delete</Button>
                    Or : <Button type="primary"
                        onClick={() => this.selectPapyri('create')}>Create a new Papyrus</Button>
                </Space>
                <Divider></Divider>
                {
                    selecteOption != '' ?
                        <Form
                            style={{ marginTop: '2%', marginLeft: '0%' }}
                            labelCol={{ span: 3 }}
                            wrapperCol={{ span: 12 }}
                            layout="horizontal"
                            onFinish={this.handleSubmit}
                            fields={this.state.selectedFieldsPapyri}>
                            {stateList.map((val: any) =>
                                val != 'papyris' &&
                                    val != 'height' &&
                                    val != 'selectedOption' &&
                                    val != 'selectedPapyri' &&
                                    val != 'selectedFieldsPapyri' &&
                                    val != 'Ink Analysis' &&
                                    val != 'Other side' &&
                                    val != 'Language' &&
                                    val != 'Other side papyrus' &&
                                    //val != 'Papyri Image' &&
                                    val != 'uploadDisable'
                                    //val != 'Papyri Images' &&
                                    //val != 'PapyriImagesBackend' 
                                    ? <Form.Item name={val} key={val} label={val}>
                                        {val == 'Publications' ||
                                            val == 'Translation' ||
                                            val == 'Content' ||
                                            val == 'Bibliography' ||
                                            val == 'Comments' ?
                                            <TextArea /> : <Input />}
                                    </Form.Item> : val == 'Ink Analysis' ?
                                        <Form.Item name={val} key={val} label={val}>
                                            <Select
                                                onClick={(event) => this.setState({
                                                    ['Ink Analysis']: (event.target as any).innerText
                                                })}
                                                style={{ width: '100%' }}>
                                                <Option value="Carbon">Carbon</Option>
                                                <Option value="Iron-Gall">Iron-Gall</Option>
                                                <Option value="Undetermined">Undetermined</Option>
                                            </Select>
                                    </Form.Item> : val == 'Other side' ?
                                        <Form.Item name={val} key={val} label={val}>
                                            <Select
                                                onClick={(event) => this.setState({
                                                    ['Other side']: (event.target as any).innerText
                                                })}
                                                style={{ width: '100%' }}>
                                                <Option value="Blank/">Blank/</Option>
                                                <Option value="Continuation on the same text or endorsement/">Continuation on the same text or endorsement/</Option>
                                                <Option value="Other_text/">Other text/</Option>
                                            </Select>
                                            </Form.Item> : val == 'Language' ?
                                        <Form.Item name={val} key={val} label={val}>
                                            <Select
                                                onClick={(event) => this.setState({
                                                    ['Language']: (event.target as any).innerText
                                                })}
                                                style={{ width: '100%' }}>
                                                <Option value="Greek">Greek</Option>
                                                <Option value="Latin">Latin</Option>
                                                <Option value="Coptic">Coptic</Option>
                                                <Option value="Hieratic">Hieratic</Option>
                                                <Option value="Pahlavi">Pahlavi</Option>
                                            </Select>
                                        </Form.Item> : val == 'Other side papyrus' &&
                                            this.state["Other side"] == 'Other text/' || otherSideSelected == 'Other text/' ?
                                            <Form.Item name='Other side papyrus' key='Other side papyrus' label='Other side papyrus'>
                                                <Select
                                                    filterOption={(input, option) =>
                                                        option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    showSearch
                                                    style={{ width: '100%' }}>
                                                    {papyris.filter((v: any) => v.uuid != selectedPapyri.uuid).map((value: any, i: any) =>
                                                        <Select.Option key={i} value={value.uuid}>{value.Title}</Select.Option>
                                                    )}
                                                </Select>
                                            </Form.Item> : ''
                            )}

                            <Form.Item ><Button type="primary" htmlType="submit">Submit</Button></Form.Item>
                        </Form> : ''
                    // val == 'Ink Analysis' ?
                    //         <Form.Item name={val} key={val} label={val}>
                    //             <Upload
                    //                 showUploadList={false}
                    //                 onChange={(file) => this.handleChangeImage(file, 'ink')}>
                    //                 <Button
                    //                     //onClick={() => this.state.uploadDisable ? message.error('Remove the previous one to enter a new Ink') : ''}
                    //                     icon={<UploadOutlined />}>{
                    //                         this.state.inkName === '' && (inkAnalysisSelected == undefined || inkAnalysisSelected === '')
                    //                             ? 'Click to Upload' : this.state.inkName || 'Ink already Uploaded'}</Button>
                    //             </Upload>
                    //         </Form.Item> :
                    // val == 'Papyri Images' ?
                    //     <Form.Item name={val} key={val} label={val}>
                    //         <Upload
                    //             name="papyris"
                    //             listType="picture-card"
                    //             className="avatar-uploader"
                    //             fileList={this.state['Papyri Images']}
                    //             // beforeUpload={this.beforeUpload}
                    //             onChange={(fileList) => this.handleChangeImage(fileList, 'papyri')}
                    //         >
                    //             <PlusOutlined />
                    //             <div style={{ marginTop: 8 }}>Upload</div>
                    //         </Upload>
                    //     </Form.Item> : 
                }
            </div >)
    }
}
export default Papyri