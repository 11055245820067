import * as React from "react"
// import { Typography, Divider } from 'antd';
import Layout from "antd/lib/layout";
// import Img from "antd/lib/image"
import Typography from "antd/lib/typography";
import useWindowSize from "../useWindowSize";
// import Space from "antd/lib/space";
// import Mirador from "./Mirador"
// import { instance } from "../axios-instance"
// import { logo } from "../logo";

const { Sider, Content } = Layout;


const { Title, Paragraph, Link } = Typography

// Nella home non dobbiamo più mostrare mirador ma una descrizione come qui
// https://spotlight.vatlib.it/greek-paleography
const Home = () => {
    const size = useWindowSize()
    return (
        <Layout className='layout' style={{
            padding: '0 175px 0px 0',
            // height: 'auto',
            flex:1,
            justifyContent: "center",
            alignItems: "center",
            // width: '100%',
            backgroundImage: "url('./images/photo_2021-06-03_12-11-10.jpg')",
            // backgroundRepeat: 'round',
        }}>
            {size.height >= 800 &&
                <Sider style={{ background: 'trasparent', paddingTop: '0%', opacity: 0 }} />
            }
            <Content style={{
                opacity: 1, backgroundColor: '#ffffff', padding: '0px 35px 0px 35px'
            }}>
                <Title style={{fontFamily:'Swift Neue LT W01'}}>Introduction</Title>
                <Paragraph>
                   <Title style={{fontFamily:'Swift Neue LT W01'}} level={3}>{`Welcome to the online catalogue of the Basel Papyrus Collection`}</Title>
                </Paragraph>
                <Paragraph>
                   <Title style={{fontFamily:'Verdana'}} level={5}> {`You will find here:`}</Title>
                </Paragraph>
                <Paragraph style={{fontFamily:'Verdana'}}>
                    <ul className='dash' style={{ listStyleType: 'none' }}>
                        <li>
                            {`Images of the Basel papyri (More details on the various kinds of images available in the dedicated paragraph below)`}
                        </li>
                        <li>
                            {`The main information on each papyrus contained in P.Bas. II as well as bibliographical updates posterior to the publication`}
                        </li>
                        <li>
                            {`The samples used for ink analysis (More details in the dedicated paragraph below)`}
                        </li>
                    </ul>
                </Paragraph>
                <Paragraph style={{fontFamily:'Verdana'}} >
                    {`For the presentation of the collection and the SNSF project, see `}
                    <Link href="https://altegeschichte.philhist.unibas.ch/de/forschung/forschungsprojekte/edition-pbas/">{`here`}</Link>{`.`}
                </Paragraph>
                <Paragraph style={{fontFamily:'Verdana'}} >
                    {`Access the catalogue by  `}
                    <Link href="/Papyri">{`browsing or searching`}</Link>{`.`}
                </Paragraph>
                <Title style={{fontFamily:'Verdana'}} level={5}>{`Imaging the collection`}</Title>
                <Paragraph style={{fontFamily:'Verdana'}}>
                    {`For each papyrus, the catalogue contains `}
                </Paragraph>
                <Paragraph style={{fontFamily:'Verdana'}}>
                    <ul className='dash' style={{ listStyleType: 'none' }}>
                        <li>
                            {`the color image published in P.Bas. II,`}
                        </li>
                        <li>
                            {`a color image with scales,`}
                        </li>
                        <li>
                            {`an infra-red image`}
                        </li>
                        <li>
                            {`various enhancements obtained with Hierax software, see `}
                            <Link href="https://hierax.ch/">{`hierax.ch`}</Link>
                        </li>
                    </ul>
                </Paragraph>
                <Paragraph style={{fontFamily:'Verdana'}}>
                    {`We would like to thank the Digital Humanities Lab for the digitization work.`}
                </Paragraph>
                <Title style={{fontFamily:'Univers LT W01 45 Bold'}} level={5}>{`Ink Analysis`}</Title>
                <Paragraph style={{fontFamily:'Verdana'}}>
                    {`Ink analysis has been conducted by Isabelle Marthot-Santaniello with the collaboration of Tea Ghigo
                        and Sebastian Bosch from BAM (Federal Institute of the Materials Research and Testing, Berlin) and
                        CSMC (Centre for the Study of Manuscript Cultures, Hamburg). See the details in `}
                        <Link href = "https://www.degruyter.com/document/doi/10.1515/9783110680973/html">{`P.Bas. II`}</Link>{` p. XVI.`}
                </Paragraph>
                <Paragraph style={{fontFamily:'Verdana'}}>
                    {`The analyses aimed to distinguish the presence of carbon, which remains opaque under nearinfrared light, and of iron-gall ink, which loses its opacity, using Near-infrared reflectography. The
                        samples obtained using the USB microscope DinoLite (ultra-violet, near-infrared and visible light) are
                        made available for each papyrus.`}
                </Paragraph>
                <Paragraph style={{fontFamily:'Verdana'}}>
                    {`Only the Pahlavi drawing had a specific ink analysis of its own. It has not been possible to run an ink analysis on the Pahlavi text.`}
                </Paragraph>
                <Paragraph style={{fontFamily:'Verdana'}}>
                    {`We would like to thank the Basel University Library for their support.`}
                </Paragraph>
            </Content>
        </Layout >

        // <div >
        //     <Mirador config={{ id: "mirador" }} />
        // </div>
    )
}
export default Home