/* eslint-disable */
import * as React from "react"
import Title from 'antd/lib/typography/Title'
import Paragraph from 'antd/lib/typography/Paragraph'
import Select from 'antd/lib/select'
import Upload from 'antd/lib/upload'
import Row from 'antd/lib/row'
import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined'
import UploadOutlined from '@ant-design/icons/UploadOutlined'
import Modal from 'antd/lib/modal'
UploadOutlined

import PlusOutlined from '@ant-design/icons/PlusOutlined'

import { instance } from "../../axios-instance";
import Button from "antd/lib/button";
import Image from "antd/lib/image"
import Space from "antd/lib/space"
import { Col } from "antd/lib/grid"
import message from 'antd/lib/message'
import Tooltip from "antd/lib/tooltip"
import Divider from "antd/lib/divider"

const base64Image = (info: any) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(info.file.originFileObj);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    })
}
interface IState {
    papyris: []
    selectedPapyri: any;
    dataInk: any[]
    dataImages: any[]
    disabeled: []
    images: number;
    remoteImages: any[],
    removedImages: number[],
    showDeleteModal: boolean,
    image_name: string
}

interface IProps {
    getPapyris: any,
    papyris: [],
    location: any
}



class Images extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props)
        this.state = {
            papyris: [],
            selectedPapyri: '',
            disabeled: [],
            dataImages: [],
            remoteImages: [],
            dataInk: [],
            images: 1,
            removedImages: [],
            showDeleteModal: false,
            image_name: ''
        }

    }
    componentDidMount() {
        this.setState({ papyris: this.props.papyris })
    }
    componentDidUpdate(prevProps: any, prevState: any) {
        prevProps.papyris != this.props.papyris ? this.setState({ papyris: this.props.papyris }) : ''
        prevState.selectedPapyri != this.state.selectedPapyri ? this.getImages() : ''
    }
    getImages = () => (this.setState({ dataImages: [] }), instance
        .post('load_image', { papyri_uuid: this.state.selectedPapyri.uuid })
        .then(res => this.setState({ remoteImages: res.data })))

    uploadImage = (event: any, type: string) => {
        const form = new FormData()
        form.append('file', event.target.files)
        type == 'image' ?
            this.setState({ dataImages: this.state.dataImages.concat(form) }) :
            this.setState({ dataInk: this.state.dataInk.concat(form) })
        const images = new FormData()
        this.state.dataImages.map((image, i) => {
            images.append('file', image.originFileObj)
            this.state.dataInk[i] ? images.append('file', this.state.dataInk[i].originFileObj) : ''
        })
        instance.post('upload_file', images)
            .then(res => (message.success(res.data as string), this.onFinish()))
            .catch(err => message.error(err as string))
        // var inputs = document.querySelectorAll('input')
        // inputs.forEach(input => input.value != '' ? input.classList.add('ant-input-disabled') : '')
    }

    onDeleteImage = (image_name: string) => this.setState({ showDeleteModal: true, image_name })



    onFinish = () =>
        instance.post('post_image', {
            filename: this.state.dataImages[0]['name'], papyri_uuid: this.state.selectedPapyri['uuid']
        })
            .then(res => message.success(res.data as string))
            .catch(err => message.error(err as string))
            .finally(() => (this.getImages()))

    render() {
        const { papyris, dataImages, selectedPapyri, images, removedImages, dataInk, remoteImages, image_name } = this.state
        return (<>
            Select a Papyrus: <Select
                filterOption={(input, option) =>
                    option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                showSearch
                optionFilterProp="children"
                onSelect={uuid => this.setState({ selectedPapyri: papyris.find((val: any) => val.uuid === uuid) })}
                style={{ width: '52.5%', marginRight: '2%' }}>
                {papyris.map((val: any, i: any) =>
                    <Select.Option key={i} value={val.uuid}>{val.Title}</Select.Option>
                )}
            </Select>

            { selectedPapyri != '' && <div style={{ margin: '1.5% 0' }}>
                <Title level={4}>Upload your images (upload one image at time, click on submit and wait for the image to appear on the right)</Title>
                <Row gutter={[16, 16]}>
                    <Col flex="auto">Upload Image: <Upload name="logo" listType="picture"
                        fileList={dataImages}
                        onChange={(file: any) => dataImages.findIndex(val => val == file) == -1 ? this.setState({ dataImages: file.fileList }) : ''}>
                        <Button icon={<UploadOutlined />}>Click to upload</Button></Upload></Col>
                    <Col style={{ overflowX: window.innerWidth < 950 ? 'scroll' : 'hidden' }}>
                        <Space align="start">
                            {selectedPapyri != '' && remoteImages.length != 0 && remoteImages.map((src, key) =>
                                <Space direction="vertical" key={key as unknown as string}>
                                    <Tooltip title={src['name']}>
                                        <Image height={100} width={100} src={'data:image/png;base64,' + src['image']} />
                                    </Tooltip>
                                    <a onClick={() => this.onDeleteImage(src['name'])}>Delete</a>
                                </Space>)}
                        </Space>
                    </Col>
                    {/* <Col>Upload Ink.Analysis: < Upload name="logo" listType="picture" onChange={(file: any) => dataInk.findIndex(val => val == file) == -1 ? this.setState({ dataInk: file.fileList }) : ''}>
                        <Button icon={<UploadOutlined />}>Click to upload</Button>
                    </Upload></Col> */}
                </Row>
                <Button onClick={() => this.uploadImage(event, "type")}>Submit</Button>
                <Modal title={<Space align='baseline'><ExclamationCircleOutlined /> <Paragraph>Are you sure delete this image?</Paragraph></Space>}
                    visible={this.state.showDeleteModal}
                    okType='danger'
                    okText='Yes'
                    cancelText='No'
                    onOk={() => instance
                        .post('delete_image', { image_name, papyri_uuid: selectedPapyri['uuid'] })
                        .then(res =>
                            res.data == 'The image was deleted!' ? (message.success(res.data as string),
                                this.setState({
                                    remoteImages: this.state.remoteImages.filter((val: any) => val.name != image_name),
                                })
                            ) : message.error(res.data as string + ', The image has not deleted!'))
                        .catch(err => message.error(err as string + ', The image has not deleted!'))
                        .finally(() => this.setState({ showDeleteModal: false, image_name: '' }))}
                    onCancel={() => this.setState({ showDeleteModal: false, image_name: '' })} >
                    <p>If you select yes you will no longer be able to recover this image </p></Modal>
            </div>

                //         <Form style={{ marginTop: '1.5%' }} name="dynamic_form_nest_item" onFinish={this.onFinish} autoComplete="off">
                //             <Form.List name="images">
                //                 {(fields, { add, remove }) => (
                //                     <>
                //                         {fields.map((field, i) => (
                //                             <Space key={field.key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                //                                 <Form.Item
                //                                     {...field}
                //                                     name={[field.name, 'image']}
                //                                     fieldKey={[field.fieldKey, 'image']}
                //                                 >
                //                                     {i + 1}. Upload Image: <Upload name="logo" action="./" listType="picture">
                //                                         <Button icon={<UploadOutlined />}>Click to upload</Button>
                //                                     </Upload>
                //                                     {/* {i + 1}. Upload Image: <Input style={{ width: 'fit-content' }} type="file" name="file"
                //                                     // onChange={(e) => this.uploadImage(e, 'image')} 
                //                                     /> */}
                //                                 </Form.Item>
                //                                 <Form.Item
                //                                     {...field}
                //                                     name={[field.name, 'ink']}
                //                                     fieldKey={[field.fieldKey, 'ink']}
                //                                 >
                //                                     Upload Ink.Analysis:<Upload name="logo" action="./" listType="picture">
                //                                         <Button icon={<UploadOutlined />}>Click to upload</Button>
                //                                     </Upload>
                //                                     {/* Upload Ink.Analysis: <Input style={{ width: 'fit-content' }} type="file" name="file"
                //                                     //onChange={(e) => this.uploadImage(e, 'ink')} 
                //                                     /> */}
                //                                 </Form.Item>
                //                                 {/* <MinusCircleOutlined onClick={() => remove(field.name)} /> */}
                //                             </Space>
                //                         ))}
                //                         <Form.Item>
                //                             <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                //                                 {'Add field'}
                //                             </Button>
                //                         </Form.Item>
                //                     </>
                //                 )}
                //             </Form.List>
                //             <Form.Item>
                //                 <Button type="primary" htmlType="submit">
                //                     Submit
                // </Button>
                //             </Form.Item>
                //         </Form>
                //     }
                /* <Select
                                        filterOption={(input, option) =>
                                            option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        showSearch
                                        optionFilterProp="children"
                                        onSelect={uuid => this.setState({ selectedPapyri: papyris.find((val: any) => val.uuid === uuid) })}
                                        style={{ width: '52.5%', marginRight: '2%' }}>
                                        {papyris.map((val: any, i: any) =>
                                            <Select.Option key={i} value={val.uuid}>{val.Title}</Select.Option>
                                        )}
                                    </Select>
                                    {selectedPapyri != '' && <div style={{ margin: '1.5% 0' }}>
                                        <Title level={4}>Upload yuor images</Title>
                                        {[...Array(images)].map((image, i) =>
                                            removedImages.findIndex(rem => rem == i) == -1 &&
                                            <Row key={i} gutter={[16, 16]}>
                                                <Col >{i + 1}.
                            Upload Image: <Input style={{ width: 'fit-content' }} type="file" name="file" onChange={(e) => this.uploadImage(e, 'image')} />
                                                </Col>
                                                <Col>Upload Ink.Analysis: <Input style={{ width: 'fit-content' }} type="file" name="file" onChange={(e) => this.uploadImage(e, 'ink')} /></Col>
                                                <Col><Button onClick={() => this.setState({ dataImages: dataImages.splice(i, 1), removedImages: removedImages.concat(i) })}>-</Button></Col>
                                            </Row>
                                        )}
                                        <Button onClick={() => this.setState({ images: images + 1 })}>+</Button>
                                    </div>
                                    }
        
        
        
                                    {/* <Upload onChange={(info) => info.file.percent == 100 && info.file.status == 'uploading' && this.uploadImage(info.file.originFileObj)} {...this.upload}>
                    <Button icon={<UploadOutlined />}>Upload</Button>
                </Upload> */
            }</>)
    }
}
export default Images