import React, { Component } from "react";
import mirador from "mirador";
import "./mirador.css"



class Mirador extends Component {

    // const getPapiry = () => instance.get('get_manifests') 
//     constructor(props){
//     super(props);
//     this.state = {
//       id: this.props.config.id,
//       uuid: this.props.config.uuid
//     };
//   }


   
    componentDidMount() {

        mirador.viewer({
            
            id: 'mirador',
            windows: [{
                imageToolsEnabled: true,
                imageToolsOpen: true,
                manifestId: "https://philhist-papyri-01.philhist.unibas.ch/api/iiif/"+this.props.config.uuid+"/manifest",
                view: 'gallery'
            }],
            window: {
                        allowClose: true, // Configure if windows can be closed or not
                        allowFullscreen: true, // Configure to show a "fullscreen" button in the WindowTopBar
                        allowMaximize: true, // Configure if windows can be maximized or not
                        allowTopMenuButton: true, // Configure if window view and thumbnail display menu are visible or not
                        allowWindowSideBar: true, // Configure if side bar menu is visible or not
                        authNewWindowCenter: 'parent', // Configure how to center a new window created by the authentication flow. Options: parent, screen
                        sideBarPanel: 'info', // Configure which sidebar is selected by default. Options: info, attribution, canvas, annotations, search
                        defaultSidebarPanelHeight: 201,  // Configure default sidebar height in pixels
                        defaultSidebarPanelWidth: 235, // Configure default sidebar width in pixels
                        defaultView: 'gallery',  // Configure which viewing mode (e.g. single, book, gallery) for windows to be opened in
                        forceDrawAnnotations: false,
                        hideWindowTitle: false, // Configure if the window title is shown in the window title bar or not
                        highlightAllAnnotations: false, // Configure whether to display annotations on the canvas by default
                        showLocalePicker: false, // Configure locale picker for multi-lingual metadata
                        sideBarOpen: true, // Configure if the sidebar (and its content panel) is open by default
                        switchCanvasOnSearch: true, // Configure if Mirador should automatically switch to the canvas of the first search result
            },
            
            export: {
                catalog: true,
                companionWindows: true,
                config: true,
                elasticLayout: true,
                layers: true,
                viewers: true,
                windows: true,
                workspace: true,
            },
            thumbnailNavigation: {
                defaultPosition: false,
            },
            views: [
                { key: 'single', behaviors: ["individuals"]},
                { key: 'gallery'}
            ],
            panels: { // Configure which panels are visible in WindowSideBarButtons
                info: true,
                attribution: true,
                canvas: true,
            },
            workspace: {
                draggingEnabled: true,
                allowNewWindows: true,
                isWorkspaceAddVisible: false,
                height: 5000,
                viewportPosition: {x:0, y: 0},
                showZoomControls: true,
                type: 'mosaic', // Which workspace type to load by default. Other possible values are "elastic"
            },
            workspaceControlPanel: {
                enabled: false,
            }
        })
    }
    componentDidUpdate() {

        mirador.viewer({
            
            id: 'mirador',
            windows: [{
                imageToolsEnabled: true,
                imageToolsOpen: true,
                manifestId: "https://philhist-papyri-01.philhist.unibas.ch/api/iiif/"+this.props.config.uuid+"/manifest",
                view: 'gallery'
            }],
            window: {
                        allowClose: true, // Configure if windows can be closed or not
                        allowFullscreen: true, // Configure to show a "fullscreen" button in the WindowTopBar
                        allowMaximize: true, // Configure if windows can be maximized or not
                        allowTopMenuButton: true, // Configure if window view and thumbnail display menu are visible or not
                        allowWindowSideBar: true, // Configure if side bar menu is visible or not
                        authNewWindowCenter: 'parent', // Configure how to center a new window created by the authentication flow. Options: parent, screen
                        sideBarPanel: 'info', // Configure which sidebar is selected by default. Options: info, attribution, canvas, annotations, search
                        defaultSidebarPanelHeight: 201,  // Configure default sidebar height in pixels
                        defaultSidebarPanelWidth: 235, // Configure default sidebar width in pixels
                        defaultView: 'single',  // Configure which viewing mode (e.g. single, book, gallery) for windows to be opened in
                        forceDrawAnnotations: false,
                        hideWindowTitle: false, // Configure if the window title is shown in the window title bar or not
                        highlightAllAnnotations: false, // Configure whether to display annotations on the canvas by default
                        showLocalePicker: false, // Configure locale picker for multi-lingual metadata
                        sideBarOpen: true, // Configure if the sidebar (and its content panel) is open by default
                        switchCanvasOnSearch: true, // Configure if Mirador should automatically switch to the canvas of the first search result
            },
            
            export: {
                catalog: true,
                companionWindows: true,
                config: true,
                elasticLayout: true,
                layers: true,
                viewers: true,
                windows: true,
                workspace: true,
            },
            thumbnailNavigation: {
                defaultPosition: false,
            },
            views: [
                { key: 'single', behaviors: ["individuals"]},
                { key: 'gallery'}
            ],
            panels: { // Configure which panels are visible in WindowSideBarButtons
                info: true,
                attribution: true,
                canvas: true,
            },
            workspace: {
                draggingEnabled: true,
                allowNewWindows: true,
                isWorkspaceAddVisible: false,
                height: 5000,
                viewportPosition: {x:0, y: 0},
                showZoomControls: true,
                type: 'mosaic', // Which workspace type to load by default. Other possible values are "elastic"
            },
            workspaceControlPanel: {
                enabled: false,
            }
        })
        

    }

    
    render() {
            const { config } = this.props
        return <div id={config.id} uuid={config.uuid} />;
    }
}

export default Mirador;