/* eslint-disable */
import * as React from "react"
import Title from 'antd/lib/typography/Title'
import Paragraph from 'antd/lib/typography/Paragraph'
import Select from 'antd/lib/select'
import Upload from 'antd/lib/upload'
import Row from 'antd/lib/row'
import Modal from 'antd/lib/modal'
import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined'
import UploadOutlined from '@ant-design/icons/UploadOutlined'
import 'antd/dist/antd.css';

import Space from 'antd/lib/space'
import Tooltip from 'antd/lib/tooltip'
import Divider from "antd/lib/divider";
import PlusOutlined from '@ant-design/icons/PlusOutlined'
import { Image, Card } from 'antd';
import 'antd/dist/antd.css';

import { instance } from "../axios-instance";
import Button from "antd/lib/button";
import { Col } from "antd/lib/grid"
import message from 'antd/lib/message'
import { useHistory } from "react-router"
import useWindowSize from "../useWindowSize"
import { Carousel } from 'antd';
import src from "*.avif"



const base64Image = (info: any) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(info.file.originFileObj);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    })
}

const contentStyle = {
    height: window.innerHeight,
    width: window.innerWidth,
    color: '#fff',
    lineHeight: '160px',
    background: '#364d79',
  };

interface IState {
    papyris: []
    selectedPapyri: any;
    dataInk: any[]
    dataImages: any[]
    disabeled: []
    images: number;
    removedImages: number[]
    remoteSamples: any[],
    showDeleteModal: boolean,
    sample_name: string
}

interface IProps {
    getPapyris: any,
    papyris: [],
    location: any
}



class Sample extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props)
        this.state = {
            papyris: props.location.state.uuid,
            selectedPapyri: '',
            disabeled: [],
            dataImages: [],
            dataInk: [],
            images: 1,
            removedImages: [],
            remoteSamples: [],
            sample_name: '',
            showDeleteModal: false
        }

    }

    componentDidMount() {
        this.setState({ papyris: this.props.papyris })
        this.getSamples()
    }

 
    getSamples = () => (this.setState({ dataImages: [] }), instance
        .post('load_sample', { papyri_uuid: this.props.location.state.uuid})
        .then(res => this.setState({ remoteSamples: res.data })))
        
    render() {
        const{remoteSamples} = this.state

               return(  
                            // <Carousel style={contentStyle} autoplay>
                            //     {remoteSamples.length != 0 && remoteSamples.map((src, key) =>
                            //     <div style={contentStyle}> 
                            //     <Image height={window.innerHeight} width={window.innerWidth} src={'data:image/png;base64,' + src['image']} />
                            //     </div>
                            
                            //     )}
                                
                            // </Carousel>
                            // <Row>  
                                // {/* <Col flex="1 0 25%"  style={{ overflowX: window.innerWidth < 950 ? 'scroll' : 'hidden' }}> */}
                                // <Space direction= 'horizontal' >
                                    
                                //     )}
                                // </Space>
                                //  <Card style={{ height: window.innerHeight / 4 * 3, border: 0, padding: 0 }}>
                                //     { remoteSamples.length != 0 && remoteSamples.map((src, key) =>

                                //             <Tooltip title={src['name']}>
                                //                     <Image height={200} width={200} src={'data:image/png;base64,' + src['image']} padding={20}/>
                                //             </Tooltip>
                                            
                                //     )}
                                //  </Card>
                                
                                <Space style={{ height: window.innerHeight / 4 * 3, border: 0, padding: 5, overflow: 'auto'}} size={[8,16]} wrap>
                                    {remoteSamples.length != 0 && remoteSamples.map((src, key) =>

                                        <Tooltip title={src['name']}>
                                                <Image height={200} width={200} src={'data:image/png;base64,' + src['image']} />
                                        </Tooltip>
                                    )}
                                </Space>

                                
                                    // <Space direction="horizontal" >
                                    //     { remoteSamples.length != 0 && remoteSamples.map((src, key) =>
                                        
                                    //         <Tooltip title={src['name']}>
                                    //                 <Image height={200} width={200} src={'data:image/png;base64,' + src['image']} />
                                    //         </Tooltip>
                                       
                                    //     )}
                                    // </Space>
                                // {/* </Col> */}
                            // {/* </Row>   */}
                           
                 )
            }

}
export default Sample