import * as React from "react"
// import { Typography, Divider } from 'antd';
// import Popover from "antd/lib/popover"
import Descriptions from "antd/lib/descriptions";
import Mirador from "./Mirador"
// import { instance } from "../axios-instance"
// import { logo } from "../logo";
import Divider from "antd/lib/divider";
import Card from "antd/lib/card"
import Typography from "antd/lib/typography";

// import Tooltip from "antd/lib/tooltip";
import Button from "antd/lib/button"
import { useHistory } from "react-router";
import useWindowSize from "../useWindowSize";

interface IProps {
    papyrus: any
}

const sortOrder = [
    'Publications',
    'Inv. Number',
    'Inv. Number before 2015',
    // 'Ink Analysis',
    'Material',
    'Provenance (GEO ID)',
    'Provenance Name',
    'Date',
    'Size',
    'TM',
    'Other side',
    // 'Other side papyrus'
    // 'Papyrus.info',
    // 'PPortal'
]

const sortOrder1 = [
    'Content',
    'Translation',
    'Comments',
    'Bibliography',
]

// Abbiamo bisongo di un altra route dove mostramiamo una semplice lista di paipri
// https://spotlight.vatlib.it/greek-paleography/catalog?f%5Bcollection_ssim%5D%5B%5D=Vat.gr.
// quando clicchiamo su di essa ci vengono mostrate le info sul papyri selezionato
// https://spotlight.vatlib.it/greek-paleography/catalog/057a039b-9ed0-408c-9892-a2073aaa6f73

const Papyrus = (props: IProps) => {
    // const [height, setHeight] = React.useState(0)
    const size = useWindowSize()
    const history = useHistory();
    const handleRoute = () =>{

        history.push(
            {
                pathname: "/Sample",
                state: {
                    uuid: props.papyrus.uuid
                }
            }
            )
    }


    // console.log(props.papyrus)
    const ref = React.useRef(null)
    React.useEffect(() => {
        // setHeight((ref?.current as any).clientHeight || 0)
    })
    // console.error()
    return (<div  >
        <Card style={{ height: size.height - 30 ,  border: 0, padding: 0}}>
            <div 
                ref={ref}
            >
                <Mirador config={{ id: "mirador" , uuid: props.papyrus.uuid }} />

            </div>
        </Card>
        <Divider />
        <Card style={{ border: 0, height:0 }}>
       
            <Descriptions title={props.papyrus.Title || 'Papyrus Title'}>
                {Object.keys(props.papyrus)
                    .filter(key => key !== 'uuid'  && sortOrder.includes(key) && props.papyrus[key] !== "" )
                    .sort((a, b) => sortOrder.indexOf(a) - sortOrder.indexOf(b))
                    .map((key, index) => 
                        <Descriptions.Item key={index} label={key}>
                            <Typography.Paragraph
                                ellipsis={{
                                    rows: 3,
                                    // tooltip: true,
                                    symbol: "Read More"
                                }}>
                                {props.papyrus[key]}
                            </Typography.Paragraph>
                        </Descriptions.Item>)}
            </Descriptions>
            Other side papyrus: <a rel="noreferrer" target="_blank" href={props.papyrus.OtherSidePapyrus} title="OtherSidePapyrus"> {props.papyrus.OtherSidePapyrus}</a>
            <br></br>
            <br></br>
            Ink Analysis: {props.papyrus["Ink Analysis"]}  {" "}             
            <Button onClick={handleRoute} type="primary">See the samples</Button>
  
            <br></br>
            <br></br>
            PPortal:<a rel="noreferrer" target="_blank" href={props.papyrus.PPortal} title="PPortal"> {props.papyrus.PPortal}</a>
            <br /><br />

            Papyri.info:<a rel="noreferrer" target="_blank" href={props.papyrus.PapyriInfo} title="Papyri.info"> {props.papyrus.PapyriInfo}</a>
            <br /><br />                        
            <Descriptions>
                {Object.keys(props.papyrus)
                    .filter(key => sortOrder1.includes(key) && props.papyrus[key]!== "" && key !== "Translation" && key !== "Comments" && key !== "Bibliography")
                    .sort((a, b) => sortOrder1.indexOf(a) - sortOrder1.indexOf(b))
                    .map((key, index) => 
                        <Descriptions.Item key={index} label={key}>
                            <Typography.Paragraph
                                ellipsis={{
                                    rows:3,
                                    expandable: true,
                                    symbol: "Read More"
                                }}>
                                {props.papyrus[key]}
                            </Typography.Paragraph>
                        </Descriptions.Item>)}
            </Descriptions>
            <Descriptions>
                {Object.keys(props.papyrus)
                    .filter(key => sortOrder1.includes(key) && props.papyrus[key]!== "" && key !== "Content" && key !== "Comments" && key !== "Bibliography")
                    .sort((a, b) => sortOrder1.indexOf(a) - sortOrder1.indexOf(b))
                    .map((key, index) =>
                        <Descriptions.Item key={index} label={key}>
                            <Typography.Paragraph
                                ellipsis={{
                                    rows:3,
                                    expandable: true,
                                    symbol: "Read More"
                                }}>
                                {props.papyrus[key]}
                            </Typography.Paragraph>
                        </Descriptions.Item>)}
            </Descriptions>
            <Descriptions>
                {Object.keys(props.papyrus)
                    .filter(key => sortOrder1.includes(key) && props.papyrus[key]!== "" && key !== "Content" && key !== "Translation" && key !== "Bibliography")
                    .sort((a, b) => sortOrder1.indexOf(a) - sortOrder1.indexOf(b))
                    .map((key, index) =>
                        <Descriptions.Item key={index} label={key}>
                            <Typography.Paragraph
                                ellipsis={{
                                    rows:3,
                                    expandable: true,
                                    symbol: "Read More"
                                }}>
                                {props.papyrus[key]}
                            </Typography.Paragraph>
                        </Descriptions.Item>)}
            </Descriptions>
            <Descriptions>
                {Object.keys(props.papyrus)
                    .filter(key => sortOrder1.includes(key) && props.papyrus[key]!== "" && key !== "Content" && key !== "Translation" && key !== "Comments")
                    .sort((a, b) => sortOrder1.indexOf(a) - sortOrder1.indexOf(b))
                    .map((key, index) =>
                        <Descriptions.Item key={index} label={key}>
                            <Typography.Paragraph
                                ellipsis={{
                                    rows:3,
                                    expandable: true,
                                    symbol: "Read More"
                                }}>
                                {props.papyrus[key]}
                            </Typography.Paragraph>
                        </Descriptions.Item>)}
            </Descriptions>
        </Card>
    </div >
    )
}
export default Papyrus