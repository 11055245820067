import * as React from "react"
import Card from 'antd/lib/card'

import Papyri from './editing/Papyri'
import Images from './editing/Images'
import Samples from './editing/Samples'
import { instance } from '../axios-instance'
import { RouteComponentProps } from "react-router-dom";

interface IState {
    page: string;
    papyris: []
}

interface IProps extends RouteComponentProps {
    user: any
}
const tabListNoTitle: any = [
    {
        key: 'Papyri',
        tab: 'Papyrus'
    },
    {
        key: 'Images',
        tab: 'Papyrus Images'
    },
    {
        key: 'Samples',
        tab: 'Papyrus Samples'
    }
];
class Edit extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props)
        this.state = {
            page: 'Papyri',
            papyris: []
        }
    }
    getPapyris = () => instance.get('get_papyris').then(res => this.setState({ papyris: res.data }))

    componentDidMount() {
        this.getPapyris()
    }

    render() {
        const contentList: any = {
            Papyri: <Papyri
                getPapyris={this.getPapyris}
                papyris={this.state.papyris} />,
            Images: <Images
                getPapyris={this.getPapyris}
                papyris={this.state.papyris}
                location={this.props.location} />,
            Samples: <Samples
                getPapyris={this.getPapyris}
                papyris={this.state.papyris}
                location={this.props.location} />
            // Information: <ProviderInfo
            //     dataProvider={this.state.provider}
            //     onChange={this.onChange}
            // />,
            // Service: <ProviderService
            //     dataProvider={this.state.provider}
            //     onChange={this.onChange}
            // />,
            // Employee: <ProviderEmployee
            //     dataProvider={this.state.provider}
            //     onChange={this.onChange}
            // />,
            // Product: <ProviderProduct
            // />,
            // Questions: <ProviderAnswers
            // />
        };
        return (<div>
            <Card
                // activeTabKey='Papyrus'
                onTabChange={key => { this.setState({ page: key }) }}
                bordered={false}
                style={{
                    width: '100%', height: '20%', top: '2%'
                }} tabList={tabListNoTitle}
            >
                {contentList[this.state.page]}
            </Card>

        </div>)
    }
}
export default Edit