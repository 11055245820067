import * as React from "react"
import { useRouteMatch, Link, useHistory } from "react-router-dom";
import Papyrus from "./Papyrus";
import { instance } from "../axios-instance"
// import { Typography, Divider } from 'antd';
import Input from "antd/lib/input";
import Button from "antd/lib/button"
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Card from "antd/lib/card"
import List from "antd/lib/list";
import Space from "antd/lib/space"
import Modal from "antd/lib/modal/Modal";
import Divider from "antd/lib/divider";
import Select from "antd/lib/select";
import { SearchOutlined, SortAscendingOutlined, SortDescendingOutlined, DownOutlined, CloseOutlined } from '@ant-design/icons';
import {useState} from "react";

// Abbiamo bisongo di un altra route dove mostramiamo una semplice lista di paipri
// https://spotlight.vatlib.it/greek-paleography/catalog?f%5Bcollection_ssim%5D%5B%5D=Vat.gr.
// quando clicchiamo su di essa ci vengono mostrate le info sul papyri selezionato
// https://spotlight.vatlib.it/greek-paleography/catalog/057a039b-9ed0-408c-9892-a2073aaa6f73

const Papiry = () => {
    const [papiry, setPapyri] = React.useState({
        initialPapiry: [],
        filteredPapiry: [],
        actualPapiry: [],
        languages: [] as any
    })
    const [visible, setVisible] = React.useState(false)
    const [showAdvance, setAdvance] = React.useState(false)
    const [papyrus, setPapyrus] = React.useState({} as any)

    const match = useRouteMatch()
    const history = useHistory()

    const getPapiry = () => instance.get('get_papyris')
        .then(res => {
            const remotePapiry = res.data
                // .filter((val: any) => val['Title'] !== 'test')
                .sort((a: any, b: any) => (a['Title'] || '').toString().split(".")[0] - (b['Title'] || '').toString().split(".")[0])

            setPapyri({
                initialPapiry: remotePapiry,
                actualPapiry: remotePapiry,
                filteredPapiry: remotePapiry,
                languages: [...new Set(remotePapiry.map((val: any) => val['Language'] && val['Language']))]
                    .filter(val => val)
                    .map((val, index) => { return { key: index, label: val, value: val } })
            })
        })

    React.useEffect(() => {
        // code to run on component mount
        getPapiry().catch(err => getPapiry())
    }, [])

    const setOrder = (type: string) => type === 'asc' ?
        setPapyri(prevState => {
            return {
                ...prevState,
                actualPapiry: prevState.actualPapiry.sort((a: any, b: any) => a['Title'].includes(".") && b['Title'].includes(".") &&
                    a['Title'].toString().split(".")[1].localeCompare(
                        b['Title'].toString().split(".")[1])
                )
            }
        }) : setPapyri(prevState => {
            return {
                ...prevState,
                actualPapiry: prevState.actualPapiry.sort((a: any, b: any) => a['Title'].includes(".") && b['Title'].includes(".") &&
                    b['Title'].toString().split(".")[1].localeCompare(
                        a['Title'].toString().split(".")[1])
                )
            }
        })
    const onApplyAdvanceFilter = (event: any) => {
        const parent = event.currentTarget.parentNode.parentElement
        var papiryList = papiry.filteredPapiry
        const TM = parent.childNodes[0].firstChild['value']
        const Publications = parent.childNodes[1].firstChild['value']
        const Inv = parent.childNodes[2].firstChild['value']
        const Language = parent.childNodes[3].firstChild.childNodes[0].innerText

        TM && TM !== '' && (
            papiryList = papiryList.filter(val => val['TM'] &&
                (val['TM'] as string) === (TM as string))
        )
        Publications && Publications !== '' && (
            papiryList = papiryList.filter(val => val['Publications'] &&
                (val['Publications'] as string).toLowerCase().includes(Publications.toLowerCase()))
        )
        Inv && Inv !== '' && (
            papiryList = papiryList.filter(val => val['Inv. Number'] &&
                (val['Inv. Number'] as string).toLowerCase().includes(Inv.toLowerCase()))
        )
        Language && Language !== 'Search for Languages' && (
            papiryList = papiryList.filter(val => val['Language'] &&
                (val['Language'] as string) === (Language as string))
        )
        setPapyri(prevState => {
            return {
                ...prevState,
                actualPapiry: papiryList
            }
        })
    }

    const modal_function = () => {
        let linkObject = {}
        let showModal = true
        if (match.params['id'] !== undefined){
            papiry.initialPapiry.filter(name => name['uuid'] === match.params['id']).map(object => (
                linkObject = object
            ))
            return(<>
                    <Modal
                        onCancel={() => { history.push('/Papyri'); setVisible(false); showModal = false;
                            setPapyrus({}) }}
                        footer={null}
                        visible={showModal}
                        width={window.innerWidth}
                        style={{ top: 0, height: '100%', overflow: 'hidden' }}
                        bodyStyle={{ overflow: 'scroll', height: window.innerHeight }}
                    >
                        <Papyrus papyrus={linkObject} />
                    </Modal>
                </>
            )
        }
    }

    const list_function = () =>{
        return (
            <>
                <List
                    header={<>
                        <Input
                            disabled={showAdvance}
                            placeholder="Search Papyrus"
                            allowClear
                            size="large"
                            onPressEnter={onSearch}
                            bordered={false}
                            prefix={<SearchOutlined />}
                            onChange={onSearch}
                            addonAfter={
                                <Button type="text" style={{ border: 10 }} onClick={() => showAdvance === true ? (setAdvance(false), onSearch()) : setAdvance(true)}>
                                    Advance {showAdvance === true ? <CloseOutlined /> : <DownOutlined />}
                                </Button>}
                        />
                        {showAdvance &&
                        <Card>
                            <Input.Group style={{ margin: '5 0' }}>
                                <Row gutter={8}>
                                    <Col span={5}>
                                        <Input placeholder="Search for TM number" />
                                    </Col>
                                    <Col span={5}>
                                        <Input placeholder="Search for Publication number" />
                                    </Col>
                                    <Col span={5}>
                                        <Input placeholder="Search for Inv.number" />
                                    </Col>
                                    <Col span={5}>
                                        <Select style={{ width: '100%' }} placeholder="Search for Languages" options={papiry.languages} />
                                    </Col>
                                    <Col span={1}><Divider type='vertical' style={{ height: '95%', marginLeft: '50%' }} /></Col>
                                    <Col span={3}>
                                        <Button type="primary" style={{ width: '100%' }} onClick={onApplyAdvanceFilter}>Apply filters</Button>
                                    </Col>
                                </Row>
                            </Input.Group>
                        </Card>}
                        <div style={{ marginTop: 9 }}>
                            <Button type="text" onClick={() => setOrder('asc')}><SortAscendingOutlined style={{ fontSize: 18 }} /></Button>
                            <Button type="text" onClick={() => setOrder('desc')}><SortDescendingOutlined style={{ fontSize: 18 }} /></Button>
                        </div>
                    </>}
                    itemLayout="horizontal"
                    dataSource={papiry.actualPapiry}
                    renderItem={(item: any) => (
                        <List.Item>
                            <List.Item.Meta
                                //   avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
                                title={<Link to={`${match.url}/${item['uuid']}`} onClick={() => (setVisible(true), setPapyrus(item))}>{item['Title']}</Link>}
                                description={<Space>
                                    <Col>{item['Provenance Name'] !== '' ? item['Provenance Name'] : 'Provenance Name'}</Col>
                                    <Divider type='vertical' />
                                    <Col>{item['Date'] !== '' ? item['Date'] : 'Date'}</Col>
                                </Space>}
                            />
                        </List.Item>
                    )}
                />
            </>
        )
    }

    // setPapyri(prevState => { return { ...prevState, isVisible: false } })
    const onSearch = (event?: any) => {
        const value = event && event.currentTarget.value
        value ?
            value !== '' ? setPapyri(prevState => {
                return {
                    ...prevState,
                    filteredPapiry: prevState.initialPapiry.filter(val => Object.values(val).some(
                        (element: any) => element.toString().toLowerCase().includes(value.toString().toLowerCase())
                    )),
                    actualPapiry: prevState.initialPapiry.filter(val => Object.values(val).some(
                        (element: any) => element.toString().toLowerCase().includes(value.toString().toLowerCase())
                    ))
                }
            }) : setPapyri(prevState => { return { ...prevState, filteredPapiry: prevState.initialPapiry, actualPapiry: prevState.initialPapiry } })
            : setPapyri(prevState => { return { ...prevState, actualPapiry: prevState.filteredPapiry } })
    }

    return (<>
            {match.params['id'] === undefined ? list_function() : modal_function()}
        </>
        // <Layout style={{ padding: '0 7.5%', height: 'auto' }}>
        //     <Sider style={{ background: '#f0f2f5', paddingTop: '0%' }}>
        //         {/* <Img src={logo} /> */}
        //     </Sider>
        //     <Content>
        //         <Title>Introduction</Title>
        //         <Paragraph>{blockContent}</Paragraph></Content>
        // </Layout>

    // <div>
    //         {papiry.initialPapiry.filter(name => name['uuid'] === "11b4ca60-6bac-11eb-a1e6-005056b34690").map(filteredName => (
    //             <li>
    //                 {filteredName['Content']}
    //             </li>
    //         ))}
    // </div>

    )
}
export default Papiry
